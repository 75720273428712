<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="sozlesme"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmamız Sözleşmeleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @changeAttribute="ChangeAttribute($event,'formdata')"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          :tabname="Math.random().toString()"
                                          @RowButtonClicked="RowButtonClicked($event)"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-dialog v-model="actionBarWait"
                          persistent>
                    <v-card color="transparent"
                            dark class="py-5">
                        <v-card-text class="text-center py-5">
                            <v-progress-circular indeterminate
                                                 :size="50"
                                                 color="white"></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var form_files = {
        tableName: 'form_files',
        columns: {
            tarih: {
                type: 'datetime',
                name: 'ad',
                label: 'FL_Date',
                edit: '',
                width: 10
            },
            fieldname:
            {
                type: 'text',
                label: 'FL_FileType',
                edit: '',
                name: 'fieldname',
                width: 10
            },
            url: {
                type: 'button',
                label: 'FL_ShowFile',
                name: 'url',
                edit: '2',
                width: 10
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                talepmiktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
                ad: { items: [], error: false, readonly: false, hidden: false },
                doviz: { items: [], error: false, readonly: false, hidden: false },

            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'sozlesme_files'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var Sozlesme_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Contract' },
        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_ContractInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'TH_FinancialInformations'
            },
            {
                tab: 1,
                order: 4,
                header: 'FL_AdditionalFiles'
            },
            {
                tab: 1,
                order: 5,
                header: 'FL_Notes'
            },
        ],

        Columns: {
            id: {
                type: 'int',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },

            durum: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },

            grup: {
                type: 'text',
                group: 2,
                sira: 10,
                name: 'grup',
                label: 'FL_ContractType',
                edit: '',
                col: '6',
            },

            konu: {
                type: 'text',
                group: 2,
                sira: 10,
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            sozlesmebaslangic:
            {
                type: 'date',
                group: 2,
                sira: 10,
                name: 'sozlesmebaslangic',
                label: 'FL_StartDate',
                edit: '',
                col: '6',
            },

            sozlesmebitis: {
                type: 'date',
                group: 2,
                sira: 15,
                name: 'sozlesmebitis',
                label: 'FL_EndDate',
                edit: '',
                col: '6',
            },

            damgavergisibilgilendirme: {
                type: 'text',
                group: 2,
                sira: 15,
                name: 'damgavergisibilgilendirme',
                label: 'FL_StampDutyOption',
                edit: '',
                col: '6',
            },

            sozlesmetutariTL: {
                type: 'money',
                group: 3,
                sira: 10,
                name: 'sozlesmetutariTL',
                label: 'FL_ContractAmountTL',
                edit: '',
                col: '6',
            },

            sozlesmetutariUSD: {
                type: 'money',
                group: 3,
                sira: 10,
                name: 'sozlesmetutariUSD',
                label: 'FL_ContractAmountUSD',
                edit: '',
                col: '6',
            },

            sozlesmetutariEUR: {
                type: 'money',
                group: 3,
                sira: 10,
                name: 'sozlesmetutariEUR',
                label: 'FL_ContractAmountEUR',
                edit: '',
                col: '6',
            },

            sozlesmetutariGBP: {
                type: 'money',
                group: 3,
                sira: 10,
                name: 'sozlesmetutariGBP',
                label: 'FL_ContractAmountGBP',
                edit: '',
                col: '6',
            },

            alert: {
                type: 'alert',
                group: 4,
                sira: 10,
                messageType: 'info',
                message: 'IM_ContractFileWordTrackChange',
                col: '12',
            },

            tedarikcifile: {
                type: 'file',
                group: 4,
                sira: 15,
                name: 'tedarikcifile',
                label: 'FL_ContractDraft',
                newFileLabel: 'FL_ContractAdd',
                showFileLabel: 'FL_ContractShow',
                edit: 'TQ',
                col: '12',
            },

            sozlesmefile: {
                type: 'file',
                group: 4,
                sira: 20,
                name: 'sozlesmefile',
                label: 'FL_SignedContractFile',
                edit: '',
                col: '12',
            },

            tedarikcidamgaekfile:
            {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'tedarikcidamgaekfile',
                label: 'FL_StampDuty',
                edit: 'Y',
                col: '12',
            },
            form_files: {
                type: 'grid',
                group: 4,
                sira: 30,
                name: 'form_files',
                label: 'TH_FileHistory',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow: false,
            },
            oonaynot: {
                type: 'history',
                group: 5,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: 'T',
                label: ''
            },
        },
        form: 'sozlesme',
        formid: 0,
        viewName: 'waitingSupplier',
        viewparameter: '',
        ignoreActions: true,
        files: ['tedarikcifile', 'sozlesmefile', 'tedarikcidamgaekfile'],
        extdata: [form_files],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { acosh } from 'core-js/core/number';

    export default {
        name: 'sozlesme',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true },
            message: Object
        },
        data: function ()
        {
            return {
                valid: false,
                showDamgaEkFile: '0',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                username: '',
                form: '',
                formname: 'sozlesme',
                openallpanel: [0],
                currentform: 'sozlesme',
                errors: [],
                trackChangeMessage: this.$root.$i18n.t('IM_ContractFileWordTrackChange'),
                tedarikciFileFormData: ''
            };
        },
        methods: {
            FormLoaded: function ()
            {
                var currstate = this.GetState;
                var formdata = this.GetFormData;
                this.tedarikciFileFormData = formdata.tedarikcifile;
                if ("T".indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    Sozlesme_formInfo.Columns.oonaynot.edit = 'T';
                    this.$store.commit('SetFormActions', [{ label: 'AL_ExpressOpinion', action: 'GericekPortal', type: 'external' }, { label: 'AL_Accept', action: 'TamamPortal', type: 'external' }]);
                    Sozlesme_formInfo.Columns.alert.message = this.$root.$i18n.t('IM_ContractFileWordTrackChange');
                    this.ClearFieldHidden('alert');
                }
                else if ("4".indexOf(formdata.damgavergisisecenegi) > -1 &&
                    formdata.tedarikcidamgaekfile == '' &&
                    "Y".indexOf(currstate) > -1 &&
                    !this.actionBarWait)
                {
                    Sozlesme_formInfo.Columns.oonaynot.edit = 'Y';
                    this.$store.commit('SetFormActions', [{ label: 'AL_UploadTaxFile', action: 'DamgaFileEkle', type: 'external' }]);
                }
                else if ("Q".indexOf(currstate) > -1)
                {
                    Sozlesme_formInfo.Columns.oonaynot.edit = 'Q';
                    this.$store.commit('SetFormActions', [{ label: 'AL_UploadSignedCopy', action: 'Tamam', type: 'external' }]);
                    Sozlesme_formInfo.Columns.alert.message = this.$root.$i18n.t('DM_UploadSignedCopy');
                    this.ClearFieldHidden('alert');
                }
                else
                {
                    this.$store.commit('SetFormActions', []);
                    this.SetFieldHidden('alert');
                }

                if ("4".indexOf(formdata.damgavergisisecenegi) > -1)
                {
                    this.ClearFieldHidden('damgavergisibilgilendirme');
                    var payload = {};
                    payload['damgavergisibilgilendirme'] = this.$root.$i18n.t('FL_StampDutyByYou');
                    this.$store.commit('SetFields', payload);

                    if ("Y".indexOf(currstate) > -1)
                    {
                        this.ClearFieldHidden('tedarikcidamgaekfile');
                    }
                    else
                    {
                        this.SetFieldHidden('tedarikcidamgaekfile');
                    }

                }
                else
                {
                    this.SetFieldHidden('damgavergisibilgilendirme');
                }
            },
            ChangeAttribute(attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            Selected: function ()
            {
                return;
            },

            NeedApprove: function (action)
            {
                var res;
                switch (action)
                {
                    case "Tamam":
                        res = '';
                        break;
                    case "Gericek":
                        res = this.$root.$i18n.t('DM_RejectContract');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
                //if (name == 'ShowRequest') {
                //    //Request formunu göster
                //    var url = '/kforms/saltalep/saltalep_frm.aspx?openformid=' + this.GetFormField('RequestId');
                //    window.open(url);
                //}
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload, tableName)
            {
                if (payload.button.name == "url")
                {
                    var openUrl = '/api/showfile?file=' + payload.item.url;
                    window.open(openUrl, '_blank');
                }
                else
                {
                    //this.OpenDynamicForm(payload);
                }
            },

            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm: function (action)
            {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var formdata = this.GetFormData;

                if (action.action == "GericekPortal")
                {
                    var err;
                    if (!formdata.oonaynot)
                    {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                    }
                    if (!formdata.tedarikcifile) {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ContractExample') });
                        this.errors.push(err);
                    }
                    else if (this.tedarikciFileFormData == formdata.tedarikcifile) {
                        err = this.$root.$i18n.t('VE_WarnContractCannotBeSame');
                        this.errors.push(err);
                    }
                }
                if (action.action == "TamamPortal")
                {
                    var err;
                    if (!formdata.oonaynot) {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                    }
                    if (!formdata.tedarikcifile)
                    {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ContractExample') });
                        this.errors.push(err);
                    }
                }

                if (action.action == "Tamam") {
                    var err;
                    if (!formdata.oonaynot) {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                    }
                    if (!formdata.tedarikcifile) {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ContractExample') });
                        this.errors.push(err);
                    }
                    else if (this.tedarikciFileFormData == formdata.tedarikcifile) {
                        err = this.$root.$i18n.t('VE_WarnSignedContractCannotBeSame');
                        this.errors.push(err);
                    }
                }
                if (action.action == "DamgaFileEkle")
                {
                    var err;
                    if (!formdata.tedarikcidamgaekfile)
                    {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_StampDuty') });
                        this.errors.push(err);
                    }
                }
                if (this.errors.length > 0)
                {
                    result = this.errors;
                }

                return result;
            },
        },
        created: function ()
        {
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', Sozlesme_formInfo);
            var form = Sozlesme_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form')
            {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0)
                {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0)
                    {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: formid
                        });
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni')
            {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else
            {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo =>
            {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            GetState: function ()
            {
                return this.$store.getters.GetFormState;
            },
        },
        watch: {
            FormAttributes: function (nval, oldval)
            {
                return;
            },

            $route: function (nval, oval)
            {
                console.log('WATCH Route :', nval, ' form:', this.formname);
                console.log('WATCH Route this:', this.$route);
                var form = Sozlesme_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form')
                {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined && Number(params.id) > 0)
                    {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0)
                        {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                }
                else if (whatToShow == 'yeni')
                {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else
                {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },
            drawer: function (n, o)
            {
                return;
            },
        }
    };
</script>
